import { useI18n } from '@/hooks/web/useI18n'
import { isUrl, isArray } from '@/utils/is'
import { ElMessage } from 'element-plus'
import { useClipboard } from '@/hooks/web/useClipboard'
import { useUserStore } from '@/store/modules/user'
import { toRaw } from 'vue'

const { copy } = useClipboard()

/**
 *
 * @param component 需要注册的组件
 * @param alias 组件别名
 * @returns any
 */
export const withInstall = <T>(component: T, alias?: string) => {
    const comp = component as any
    comp.install = (app: any) => {
        app.component(comp.name || comp.displayName, component)
        if (alias) {
            app.config.globalProperties[alias] = component
        }
    }
    return component as T & Plugin
}

/**
 * @param str 需要转下划线的驼峰字符串
 * @returns 字符串下划线
 */
export const humpToUnderline = (str: string): string => {
    return str.replace(/([A-Z])/g, '-$1').toLowerCase()
}

/**
 * @param str 需要转驼峰的下划线字符串
 * @returns 字符串驼峰
 */
export const underlineToHump = (str: string): string => {
    if (!str) return ''
    return str.replace(/\-(\w)/g, (_, letter: string) => {
        return letter.toUpperCase()
    })
}

/**
 * 驼峰转横杠
 */
export const humpToDash = (str: string): string => {
    return str.replace(/([A-Z])/g, '-$1').toLowerCase()
}

export const setCssVar = (prop: string, val: any, dom = document.documentElement) => {
    dom.style.setProperty(prop, val)
}

export const getCssVar = (prop: string, dom = document.documentElement) => {
    return getComputedStyle(dom).getPropertyValue(prop)
}

/**
 * 查找数组对象的某个下标
 * @param {Array} ary 查找的数组
 * @param {Functon} fn 判断的方法
 */
export const findIndex = <T = Recordable>(ary: Array<T>, fn: Fn): number => {
    if (ary.findIndex) {
        return ary.findIndex(fn)
    }
    let index = -1
    ary.some((item: T, i: number, ary: Array<T>) => {
        const ret: T = fn(item, i, ary)
        if (ret) {
            index = i
            return ret
        }
    })
    return index
}

export const trim = (str: string) => {
    return str.replace(/(^\s*)|(\s*$)/g, '')
}

/**
 * @param {Date | number | string} time 需要转换的时间
 * @param {String} fmt 需要转换的格式 如 yyyy-MM-dd、yyyy-MM-dd HH:mm:ss
 */
export function formatTime(time: Date | number | string, fmt: string) {
    if (!time) return ''
    else {
        const date = new Date(time)
        const o = {
            'M+': date.getMonth() + 1,
            'd+': date.getDate(),
            'H+': date.getHours(),
            'm+': date.getMinutes(),
            's+': date.getSeconds(),
            'q+': Math.floor((date.getMonth() + 3) / 3),
            S: date.getMilliseconds(),
        }
        if (/(y+)/.test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
        }
        for (const k in o) {
            if (new RegExp('(' + k + ')').test(fmt)) {
                fmt = fmt.replace(
                    RegExp.$1,
                    RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length),
                )
            }
        }
        return fmt
    }
}

/**
 * 生成随机字符串
 */
export function toAnyString() {
    const str: string = 'xxxxx-xxxxx-4xxxx-yxxxx-xxxxx'.replace(/[xy]/g, (c: string) => {
        const r: number = (Math.random() * 16) | 0
        const v: number = c === 'x' ? r : (r & 0x3) | 0x8
        return v.toString()
    })
    return str
}

/**
 * 首字母大写
 */
export function firstUpperCase(str: string) {
    return str.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase())
}

/**
 * 把对象转为formData
 */
export function objToFormData(obj: Recordable) {
    const formData = new FormData()
    Object.keys(obj).forEach((key) => {
        formData.append(key, obj[key])
    })
    return formData
}

export function formatOptions(context, items: string[]) {
    const { t } = useI18n()
    return items.map((item) => ({ label: t(context + '.' + item), value: item }))
}

export function formatSortOrder(order) {
    const obj = {
        ascending: 'asc',
        descending: 'desc',
    }
    return obj[order] ?? null
}

export function formatAgents(agents) {
    return agents.map((agent) => ({ label: agent.name, value: agent.id }))
}

export function back() {
    window.history.back()
}

// 前端控制路由生成
export const filterRoutes = (
    routes: AppRouteRecordRaw[],
    keys: string[],
): AppRouteRecordRaw[] => {
    const res: AppRouteRecordRaw[] = []

    for (const route of routes) {
        const meta = route.meta ?? {}
        // skip some route
        if (meta.hidden && !meta.canTo) {
            continue
        }

        let data: Nullable<AppRouteRecordRaw> = null

        let onlyOneChild: Nullable<string> = null
        if (route.children && route.children.length === 1 && !meta.alwaysShow) {
            onlyOneChild = route.children[0].name as string
        }

        // 开发者可以根据实际情况进行扩展
        for (const item of keys) {
            // 通过路径去匹配
            if (isUrl(item) && (onlyOneChild === item || route.name === item)) {
                data = Object.assign({}, route)
            } else {
                const routePath = (onlyOneChild ?? route.name).trim()
                if (routePath === item || meta.followRoute === item) {
                    data = Object.assign({}, route)
                }
            }
        }

        // recursive child routes - no need, just filter first level routes
        /* if (route.children && data) {
             data.children = filterRoutes(
                 route.children,
                 keys
             )
         }*/
        if (data) {
            res.push(data as AppRouteRecordRaw)
        }
    }
    //console.log(res)
    return res
}

export const pathResolve = (parentPath: string, path: string) => {
    if (isUrl(path)) return path
    const childPath = path.startsWith('/') || !path ? path : `/${path}`
    return `${parentPath}${childPath}`.replace(/\/\//g, '/').trim()
}

const getRecommendationsLink = (id: number) => {
    return 'https://recom.ae/recommendations/' + btoa('lead_' + id) + '/'
}
export const goRecommendations = (id: number) => {
    window.open(getRecommendationsLink(id), '_blank')
}
export const copyRecommendations = (id: number) => {
    copy(getRecommendationsLink(id))
    ElMessage.success('Link copied to clipboard')
}
export const formatDatetime = (datetime) => {
    return datetime.toLocaleString([], { dateStyle: 'medium', timeStyle: 'short' })
}
export const userHasRole = (role) => {
    const userStore = useUserStore()
    return userStore.getUserInfo && userStore.getUserInfo.roles.filter(r => r.name === role).length > 0
}
export const userIsCurrentAgent = (model) => {
    const userStore = useUserStore()
    return userStore.getUserInfo && userStore.getUserInfo.id === model?.agent_id
}

export const confirmWindowUnload = (e) => {
    e.preventDefault()
    e.returnValue = ''
}
export const isFormDirty = (formData, origData) => {
    return Object.keys(formData).some((key) => {
        let formValue = toRaw(formData[key])
        let origValue = toRaw(origData?.[key])

        // for upload fields, if asset has status, and it isn't success (it changes from ready to success when uploading),
        // or asset has response attribute
        // it means there's new asset not saved
        // it's not changed
        if (['images', 'videos', 'documents'].includes(key)) {
            for(const i in formValue){
                if((formValue[i]?.status && formValue[i].status !== 'success') || formValue[i]?.response) {
                    console.log(key, 'changed')
                    return true
                }
            }
            return false
        }

        // if field value is array, sort and stringify it
        // otherwise, stringify the value
        formValue = isArray(formValue) ? JSON.stringify(formValue.sort()) : formValue?.toString()
        origValue = isArray(origValue) ? JSON.stringify(origValue.sort()) : origValue?.toString()
        // for multiple select field, when value is empty array and default value not exists, it's not changed
        if ((formValue === '[]' || formValue === '') && origValue === undefined) return false

        if (formValue !== origValue) {
            console.log(key, 'changed from', origValue, 'to', formValue)
        }
        return formValue !== origValue
    })
}
export const handleUnloadEventForForm = (formData, origData) => {
    if (isFormDirty(formData, origData)) {
        window.addEventListener('beforeunload', confirmWindowUnload)
    } else {
        window.removeEventListener('beforeunload', confirmWindowUnload)
    }
}
