import { AxiosResponse, InternalAxiosRequestConfig } from './types'
import { ElMessage } from 'element-plus'
import qs from 'qs'
import { SUCCESS_CODE, TRANSFORM_REQUEST_DATA } from '@/constants'
import { useUserStoreWithOut } from '@/store/modules/user'
import { objToFormData } from '@/utils'

const defaultRequestInterceptors = (config: InternalAxiosRequestConfig) => {
    // by default, axios / JSON.stringify will remove value=undefined field
    // change to value=null, to be able to save to DB
    if (config.data) {
        Object.entries(config.data).map(entry => {
            let key = entry[0]
            let value = entry[1]
            if (value === undefined) {
                config.data[key] = null
            }
        })
    }

    if (
        config.method === 'post' &&
        config.headers['Content-Type'] === 'application/x-www-form-urlencoded'
    ) {
        config.data = qs.stringify(config.data)
    } else if (
        TRANSFORM_REQUEST_DATA &&
        config.method === 'post' &&
        config.headers['Content-Type'] === 'multipart/form-data' &&
        !(config.data instanceof FormData)
    ) {
        config.data = objToFormData(config.data)
    }
    if (config.method === 'get' && config.params) {
        let url = config.url as string
        url += '?'
        const keys = Object.keys(config.params)
        for (const key of keys) {
            if (config.params[key] !== void 0 && config.params[key] !== null) {
                url += `${key}=${encodeURIComponent(config.params[key])}&`
            }
        }
        url = url.substring(0, url.length - 1)
        config.params = {}
        config.url = url
    }
    return config
}

const defaultResponseInterceptors = (response: AxiosResponse) => {
    if (response?.config?.responseType === 'blob') {
        // 如果是文件流，直接过
        return response
    } else if (response.status === SUCCESS_CODE) {
        return response.data
    } else {
        ElMessage.error(response?.data?.message)
        if (response?.data?.code === 401) {
            const userStore = useUserStoreWithOut()
            userStore.logout()
        }
    }
}

export { defaultResponseInterceptors, defaultRequestInterceptors }
