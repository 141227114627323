import '@/styles/index.less' // 引入全局样式
import '@/plugins/animate.css' // 引入动画

import { createApp, h, DefineComponent } from 'vue'

import 'vue/jsx'
import '@/plugins/unocss' // 引入windi css
import '@/plugins/svgIcon' // 导入全局的svg图标
import { setupI18n } from '@/plugins/vueI18n' // 初始化多语言
//import { store } from '@/store' // 引入状态管理
import { setupStore } from '@/store' // 引入状态管理
import { setupGlobCom } from '@/components' // 全局组件
import { setupElementPlus } from '@/plugins/elementPlus' // 引入element-plus
import { setupRouter } from './router' // 路由
import { setupPermission } from '@/directives' // 权限
import './permission'
import App from './App.vue'
//import AppLayout from '@/layout/App.vue'

// 创建实例
const setupAll = async () => {
    const app = createApp(App)

    await setupI18n(app)

    setupStore(app)

    setupGlobCom(app)

    setupElementPlus(app)

    setupRouter(app)

    setupPermission(app)

    app.mount('#app')
}

setupAll()
/*
const appName = import.meta.env.VITE_APP_NAME || 'Laravel'
createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => {
        const page = resolvePageComponent(`./pages/${name}.vue`, import.meta.glob<DefineComponent>('./pages/!**!/!*.vue'))
        page.then((module)=>{
            module.default.layout = module.default.layout || AppLayout
        })
        return page
    },
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })

        setupI18n(app).then(()=>{
            setupGlobCom(app)
            setupElementPlus(app)
            setupPermission(app)
            app.use(store).use(plugin).use(ZiggyVue).mount(el)
        })
    },
    progress: {
        color: '#4B5563',
    },
})*/

