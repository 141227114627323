import request from '@/axios'
import type { UserType } from './types'

export const loginApi = (data: UserType): Promise<IResponse<UserType>> => {
    return request.post({ url: '/api/login', data })
}

export const loginOutApi = (): Promise<IResponse> => {
    return request.post({ url: '/api/logout' })
}
