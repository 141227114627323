<script lang="ts" setup>
import { computed } from 'vue'
import { useAppStore } from '@/store/modules/app'
import { ConfigGlobal } from '@/components/ConfigGlobal'
import { useDesign } from '@/hooks/web/useDesign'

const { getPrefixCls } = useDesign()

const prefixCls = getPrefixCls('app')

const appStore = useAppStore()

const currentSize = computed(() => appStore.getCurrentSize)

const greyMode = computed(() => appStore.getGreyMode)

appStore.initTheme()

</script>

<template>
    <ConfigGlobal :size="currentSize">
        <RouterView :class="greyMode ? `${prefixCls}-grey-mode` : ''" />
    </ConfigGlobal>
</template>

<style lang="less">
@prefix-cls: ~'@{adminNamespace}-app';

.size {
    width: 100%;
    height: 100%;
}

html,
body {
    padding: 0 !important;
    margin: 0;
    overflow: hidden;
    .size;
    font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

    #app {
        .size;
    }
}

.@{prefix-cls}-grey-mode {
    filter: grayscale(100%);
}
</style>
