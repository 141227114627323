import router from './router'
import type { RouteRecordRaw } from 'vue-router'
import { useTitle } from '@/hooks/web/useTitle'
import { useNProgress } from '@/hooks/web/useNProgress'
import { usePermissionStoreWithOut } from '@/store/modules/permission'
import { usePageLoading } from '@/hooks/web/usePageLoading'
import { NO_REDIRECT_WHITE_LIST } from '@/constants'
import { useUserStoreWithOut } from '@/store/modules/user'

const { start, done } = useNProgress()

const { loadStart, loadDone } = usePageLoading()

router.beforeEach(async (to, from, next) => {
    start()
    loadStart()
    const permissionStore = usePermissionStoreWithOut()
    const userStore = useUserStoreWithOut()
    if (userStore.getUserInfo) {
        if (to.path === '/login') {
            next({ path: '/' })
        } else {
            if (permissionStore.getIsAddRouters) {
                next()
                return
            }

            // 开发者可根据实际情况进行修改
            const roleRouters = userStore.getRoleRouters || []

            // 使用动态路由
            await permissionStore.generateRoutes(roleRouters as string[])

            permissionStore.getAddRouters.forEach((route) => {
                router.addRoute(route as unknown as RouteRecordRaw) // 动态添加可访问路由表
            })
            const redirectPath = from.query.redirect || to.path
            const redirect = decodeURIComponent(redirectPath as string)
            const nextData = to.path === redirect ? { ...to, replace: true } : { path: redirect }
            permissionStore.setIsAddRouters(true)
            next(nextData)
        }
    } else {
        if (NO_REDIRECT_WHITE_LIST.indexOf(to.path) !== -1) {
            next()
        } else {
            next(`/login?redirect=${to.path}`) // 否则全部重定向到登录页
        }
    }
})

router.afterEach((to) => {
    useTitle(to?.meta?.title as string)
    done() // 结束Progress
    loadDone()
})
